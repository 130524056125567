@import '../custom';

tr.listheaderrow th {
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
}

.itemtable thead th {
  border-width: 0;
}

.itemtable td {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  vertical-align: middle;
  border-top-width: 0;
}

.itemtable tbody tr td:first-child,
.itemtable tbody tr td:last-child {
  background: none;
}

@include media-breakpoint-down(sm) {
  .dataColumn:not(.columnVisible) {
    display: none;
  }

  th.dataColumn {
    background: black !important;
    color: white;

    .optionButtonDropdownContainer {
      float: right;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .optionButtonSpacer {
      display: inline-block;
      width: 2em;
    }

    .optionButton {
      display: flex;
      padding: 1em;
      align-items: center;
    }
  }

  @include themed() {
    td.dataColumn {
      border-left: 1px solid t('border-data-column');
    }
  }
}

@include media-breakpoint-up(md) {
  th.dataColumn {
    .optionButtonDropdownContainer,
    .optionButton,
    .optionButtonSpacer {
      display: none;
    }
  }
}

// sticky table header
.itemtable {
  position: relative;

  // sticky table headers go above the expanded mobile nav,
  // so disable them when we apply the custom `nav-expanded` class
  :global(.nav-expanded) & {
    thead {
      td,
      th {
        position: static;
      }
    }
  }

  thead {
    td,
    th {
      background: transparent;

      z-index: 1036; // just above nav bar (specifically its shadow)
      position: sticky;

      top: 76px;

      @include media-breakpoint-between(sm, md) {
        top: 66px;
      }

      @include media-breakpoint-up(lg) {
        top: 73px;
      }
    }
  }
}

@include themed() {
  .rowIcon {
    color: t('button-muted');
  }

  .sortIndicatorInactive {
    color: t('text-inactive');
  }
}
