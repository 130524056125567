@import '../custom.scss';

@include themed() {
  .darkOnly {
    display: t('dark-only-display');
  }

  .lightOnly {
    display: t('light-only-display');
  }

  .darkInverted {
    filter: t('dark-inverted-filter');
  }
}

.pageHeaderImg {
  display: block;
  margin: 0 auto;
  max-width: 50vw;

  @media (min-width: 576px) {
    // sm
    max-width: none;
  }
}

.pageHeader {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 3rem;

  @media (min-width: 576px) {
    // sm
    margin-bottom: 6rem;
  }
}

@mixin headerWash {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 230%;
  @media (min-width: 576px) {
    // sm
    background-size: unset;
  }
}

@include themed() {
  .headerWash {
    @include headerWash();
    background-image: t('bg-header-wash');
  }
}

@include themed() {
  .headerWashStashes {
    @include headerWash();
    background-image: t('bg-header-wash-stashes');
  }
}

.navBarLink {
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media (min-width: 576px) {
    // sm
    letter-spacing: 4px;
  }

  img {
    position: relative;
    top: -2px;
  }

  .navItemIcon,
  img,
  svg {
    height: 1rem;

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }

  // `.active` comes from Bootstrap
  &:global(.active) {
    color: #808080;
  }
}


.unstyledButton {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
}