@import '../custom';

.ctaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em;
    text-align: center;

    img {
      height: 150px;

      @include media-breakpoint-up(md) {
        height: 175px;
      }
    }

    .ctaButton {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    p {
      font-style: italic;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;

    .cta {
      width: 450px;
      &:not(:last-child) {
        border-right: 1px solid $gray-400;
      }
    }
  }
}