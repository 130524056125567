.StashView {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    padding: 1rem;

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
