@import '../../styles/variables';

@include media-breakpoint-up(lg) {
  .basic-multi-select.ledger-filters {
    @media (min-width: 1420px) {
      position: sticky;
      top: 13px;
      z-index: 1037; // above nav bar
    }

    &.home-screen {
      margin-top: -60px;
    }
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 350px;

    & .select {
      &__control {
        z-index: 1038; // above nav bar and sticky table headers
        padding: 2px;

        &.select__control--is-focused {
          @include ledgerplus-shadow;
        }

        &:not(.select__control--is-focused) {
          background: none;
          border-color: transparent;
          box-shadow: none;

          .select__placeholder {
            border-bottom-color: hsl(0, 0%, 50%);
          }
        }
      }

      &__menu {
        @include ledgerplus-shadow;
        margin-top: 0px;
        background: var(--filters-bg);
        z-index: 1037; // just below control shadow
      }

      &__indicator-separator {
        display: none;
      }

      &__placeholder {
        margin-left: 25px;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .basic-multi-select.ledger-filters {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    padding: 0.4rem;
    z-index: 1040;

    border-top-right-radius: 30px;

    &.ledger-filters-menu-showing {
      background: var(--filters-mobile-bg);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
      border-top-right-radius: 0;
      border-bottom-right-radius: 30px;
    }

    &:not(.ledger-filters-menu-showing) {
      width: auto;

      & .select {
        &__value-container {
          visibility: hidden;
          padding: 0;
          width: 0;
        }

        &__clear-indicator {
          visibility: hidden;
        }

        &__input {
          opacity: 0;
        }
      }
    }

    display: flex;
    flex-direction: column-reverse;

    & .select {
      &__menu {
        position: relative;
        background: transparent;
      }

      &__indicator-separator {
        display: none;
      }

      &__control {
        background: none;
        border: none;
        box-shadow: none;
      }

      &__value-container {
        > :last-child {
          width: 100%;
        }
      }
    }
  }
}

@include for-dark() {
  .ledger-filters {
    .select__control:not(.select__control--is-focused) {
      .select__placeholder {
        color: rgba(255, 255, 255, 0.7);
        border-bottom-color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.ledger-filters {
  --filters-bg: rgba(255, 255, 255, 0.95);
  --filters-mobile-bg: white;
  .select__option--is-focused {
    background-color: #deebff;
  }

  .select__multi-value__remove {
    @include text-default-theme();
  }
}

@include for-dark() {
  .ledger-filters {
    --filters-bg: rgba(21, 21, 21, 0.95);
    --filters-mobile-bg: rgba(21, 21, 21, 1);
    .select__option--is-focused {
      background-color: darken(#deebff, 75%);
    }
  }
}
