/* 
change behaviour of striped and hover tables
in order to support "extended, faded edges"
on hovered rows
*/

$edge-width: 2%;
$hover-transition-time: 0.05s;

.table-hover.table-striped {
  // To help avoid a delayed, flashing effect when the theme changes, we disable
  // the transition on the row background unless the table itself is being hovered.
  &:hover {
    tr {
      transition: background $hover-transition-time;
    }
  }

  tr td.edge {
    width: 0px;
    padding: 0;
  }
}

@include themed() {
  .table {
    color: t('text');
  }

  .table-hover.table-striped tbody tr {
    @media (max-width: 1199px) {
      &:nth-of-type(odd),
      &:nth-of-type(even) {
        &:hover {
          background: t('bg-table-hover');
        }
      }
    }

    &:nth-of-type(odd) {
      & {
        background: t('bg-table-accent');
      }
    }

    color: t('text');
  }
}

@include themed() {
  // (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm)
  // removes support for Safari: http://browserhacks.com/#hack-ac2480b5c83038f2d838e2a62e28a307
  @media (min-width: 1200px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    .table-hover.table-striped thead tr::before {
      content: '';
    }
    .table-hover.table-striped tbody tr {
      &:nth-of-type(even) {
        &:hover,
        & {
          background: t('bg');
        }
      }

      &:nth-of-type(odd) {
        &:hover,
        & {
          // "hard edges" horizontally inset from the row outer edges
          background: linear-gradient(
            to right,
            rgba(t('bg-table-accent'), 0),
            rgba(t('bg-table-accent'), 0) $edge-width,
            t('bg-table-accent') $edge-width,
            t('bg-table-accent') (100% - $edge-width),
            rgba(t('bg-table-accent'), 0) (100% - $edge-width),
            rgba(t('bg-table-accent'), 0)
          );
        }
      }

      // because `position: relative` doesn't work for tr
      transform: scale(1) !important;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // "soft edges" fading out toward the row outer edges
        background-image: linear-gradient(
          to right,
          rgba(t('bg-table-hover'), 0),
          t('bg-table-hover') $edge-width,
          t('bg-table-hover') (100% - $edge-width),
          rgba(t('bg-table-hover'), 0)
        );
        z-index: -1;
        transition: opacity $hover-transition-time linear;
        opacity: 0;
      }

      &:hover::before {
        opacity: 1;
      }
    }

    .table-hover.table-striped tr td.edge {
      width: $edge-width;
    }
  }
}
