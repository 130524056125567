@import '../../custom.scss';

.AddToStashTooltip {
  font-size: 1rem;

  &__buttongroup {
    background: $gray-400;
    display: flex;

    /**
     * CSS modules make it so we can't use `.btn` in place of `*`
     * so we need to add !important to override Bootstrap's default styles
     */

    & > * {
      border-radius: 0 !important;
    }
    & > *:not(:first-child) {
      margin: 0 !important;
    }
    & > *:not(:last-child) {
      margin-right: 1px !important;
    }
  }

  &__stashlist {
    text-align: left;
    max-height: 180px;
    overflow-y: auto;
    border-bottom: 1px solid $gray-400;

    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0.33rem 0.5rem;

      &:hover {
        background: $gray-100;
      }

      &__name {
        flex-grow: 1;
        padding-right: 1em;
      }
    }
  }
}

.newStashNameContainer {
  flex-grow: 100;
  background-color: white;
  padding: 5px;

  input {
    border: 0;
    padding: 0;
  }
}
