@import '../custom.scss';

.itemdescription.forCard {
  font-family: 'Bookmania';
}

.itemdescription strong {
  font-weight: bold;
  font-style: italic;
}

.itemdescription table {
  @extend .table-responsive;
  margin-bottom: 1rem;
}

.itemdescription table th,
.itemdescription table td {
  padding: 0.4rem;
}

.itemdescription table thead {
  border-bottom: 2px solid #ddd;
}

.itemdescription table tr {
  border-top: 1px solid #ddd;
}

.flavourpreview {
  font-style: italic;
}

.flavourpreview em {
  font-style: normal;
}
