@import '../../custom';

.ImageUpload {
  cursor: pointer;
  position: relative;
}

@include themed() {
  .ImageUpload:after {
    content: "Click or drag 'n' drop to replace image";
    font-size: larger;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: t('bg-image-upload-hover');
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ImageUpload:hover:after {
  opacity: 1;
}

.ImageUpload-busy:hover:after {
  opacity: 0;
}

.squarediv {
  position: relative;
  width: 100%;
}

.squarediv:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.squarediv_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.squarediv_content-placeholder {
  border: 3px dashed #eee;
}

@keyframes bounce {
  from,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
  animation-duration: 1s;
}

@keyframes shake {
  from,
  30% {
    transform: rotate3d(0, 0, 0, 0);
  }

  5% {
    transform: rotate3d(0, 0, 1, 45deg);
  }
  10% {
    transform: rotate3d(0, 0, 1, -35deg);
  }

  15% {
    transform: rotate3d(0, 0, 1, 25deg);
  }

  20% {
    transform: rotate3d(0, 0, 1, -15deg);
  }

  25% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
