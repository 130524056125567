@import '../custom';

$edge-height: 20px;
$footer-height: 240px;
$footer-bottom-overhang: 50px;

.pageContainer {
  display: flex;
  flex-direction: column;

  position: relative;
  min-height: 100vh;
  padding-bottom: $edge-height + $footer-height + 20px;
}

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  background: black;

  &:before {
    content: '';
    left: 0;
    right: 0;
    top: -$edge-height;
    position: absolute;
    background: url(../assets/images/page-footer-edge.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: $edge-height;
  }

  .Footer_ctaContainer {
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;

      & > :not(:last-child) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }

  .Footer_copyright {
    color: #999;
    font-size: 13px;
  }
}
