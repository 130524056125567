@import './variables';

@include themed() {
  .modal-dialog.saddlebag-item-modal .modal-body {
    padding: 1rem 2rem;

    @include media-breakpoint-up(sm) {
      &::after {
        // fade-to-white at bottom (hint to scroll)
        content: '';
        background: t('bg');
        background: linear-gradient(
          0deg,
          transparentize(t('bg'), 0) 0%,
          transparentize(t('bg'), 1) 100%
        );
      }
    }
  }
}

.modal-dialog.saddlebag-item-modal {
  pointer-events: all;

  .modal-header {
    border-bottom: none;

    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .modal-body {
    padding: 1rem 2rem;

    @include media-breakpoint-up(sm) {
      &::after {
        // fade-to-white at bottom (hint to scroll)
        height: $modal-fade-to-white-height;

        position: absolute;
        bottom: 0;
        left: calc(100% * 5 / 12); // width of col-sm-5
        right: 2rem;

        pointer-events: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @media (min-height: 700px) {
      // see also ItemDetailModal.module.scss
      margin-top: 10vh;
      margin-bottom: 0;
      max-height: 80vh;

      .modal-content {
        max-height: 80vh;
        overflow-y: hidden;
      }
    }

    .modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 90vw;
  }

  @include media-breakpoint-up(lg) {
    max-width: 700px;
  }
}

.modal-dialog.saddlebag-item-card {
  pointer-events: all;
  margin-top: 0;

  @include media-breakpoint-up(xs) {
    max-width: 95vw;
  }

  @include media-breakpoint-up(sm) {
    max-width: 444px;
  }
}
