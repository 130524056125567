@import '../custom';
@import '../styles/variables';
@import './common.module.scss';

@media (min-height: 700px) {
  // see also modal.scss
  @include media-breakpoint-up(md) {
    .detailcolumn {
      max-height: 70vh;
      overflow-y: auto;
    }
  }
}

.detailcolumn {
  // part of fade-to-white height (not full as description includes bottom margin)
  padding-bottom: calc($modal-fade-to-white-height / 2);
}

.buttoncontainer {
  $vertical-padding: 10px;

  padding: $vertical-padding 20px;
  @include media-breakpoint-up(sm) {
    margin-bottom: $modal-fade-to-white-height - $vertical-padding;
  }
}

@include themed() {
  .cardHeader::after {
    background-image: t('bg-card-header-wash');
  }
}

.cardHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  position: relative;
  height: 100px;
  padding: 10px;
  padding-top: 15px;

  > :not(:last-child) {
    margin-right: 1rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    background-position: top center;
    background-size: cover;
    width: 983px;
    height: 101px;
    z-index: -1;

    @media (max-width: 983px) {
      width: 100vw;
    }
  }
}

.washCardHeaderBack {
  position: fixed;
  z-index: 900;
  top: -30px;
  left: 50%;
  transform: translate(-50%);
}

.cardCommon {
  @include ledgerplus-shadow;
  border-radius: 20px;
  position: relative;
}

@include themed() {
  .cardCommon {
    background: t('bg-card-edge');
  }
}

.card {
  @extend .cardCommon;
  z-index: 2;
  padding: 10px;
  margin-top: 10px;

  &::after {
    @extend .darkInverted;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-image: url(../assets/images/card-bottom-design.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 20px;
  }
}

@include themed() {
  .card {
    box-shadow: t('shadow-card');
  }
}

.cardContent {
  border-radius: 12px;
  background: white;
  overflow: hidden;
}

@include themed() {
  .cardTextContent {
    padding: 20px;
    padding-bottom: 60px;
    background: t('bg-card');
    background-size: cover;
  }
}

.subCard {
  @extend .cardCommon;
  z-index: 1;

  border-top-left-radius: unset;
  border-top-right-radius: unset;
  margin: 0 20px 20px;
}

.subCardMeta {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  position: relative; // for the ::after

  & > * {
    flex-grow: 1;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background-image: url(../assets/images/card-saddlebag-watermark.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    // background-position-y: 20px;
  }
}

@include themed() {
  .subCard {
    background: t('bg-subcard');

    > div {
      padding: 20px;
    }

    > div + div {
      border-top: 1px solid $gray-500;
    }
  }

  .cardItemName {
    font-family: 'Mr. Eaves';
    font-size: 1.7rem;
    line-height: 1.7rem;
    margin-bottom: 0.3rem;
    color: t('text-card-item-name');
  }
}

.cardMetaDescription {
  font-family: 'Vollkorn';
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  letter-spacing: 0.1px;
  font-style: italic;
  line-height: 1.25rem;
  margin-bottom: 1em;
}

.cardCornerRibbon {
  display: flex;
  flex-direction: column;
  border-right: 60px solid $link-color !important;
  border-bottom: 60px solid transparent !important;
  height: 170px;
  border-top-right-radius: 12px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cardRibbonIcon {
  margin-right: -60px;

  &:first-child {
    width: 34px;
    margin-left: 13px;
    margin-top: 20px;
  }

  &:last-child {
    width: 40px;
    margin-left: 10px;
    margin-top: 10px;
  }
}

.cardCloseButton {
  $size: 2em;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 14px;
  left: 14px;
  border-radius: 1000px;
  color: $gray-700;
  background-color: rgba(220, 220, 220, 0.3);
  height: $size;
  width: $size;
  line-height: $size;

  &:active,
  &:hover {
    background-color: rgba(35, 35, 35, 0.7);
    color: white;
  }
}
