@import '../custom.scss';

.Dialog {
  &__prompt_input {
    width: 100%;
    padding: 1px 1rem;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
  }
}

.DialogButton {
  color: map-get($map: $theme-colors, $key: secondary);

  &__primary {
    color: map-get($map: $theme-colors, $key: primary);
  }
}
