@import '../../custom.scss';

.StashPreview {
  flex-grow: 1;
  margin-bottom: 2rem;
  padding: 3px;

  cursor: pointer;

  transition: background 0.1s;

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    text-align: center;
    font-weight: bold;
  }

  &__title_new_stash {
    font-weight: normal;
  }
}

@include themed() {
  .StashPreview:hover {
    background: t('bg-stash-preview-hover');
  }
}

$gray: #ddd;

.StashThumbnail {
  &__emptycontainer {
    display: flex;
    align-items: center;
    justify-content: center;

    color: map-get($map: $theme-colors, $key: primary);
  }

  &__imagecontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    border: 0.5px solid $gray;
    border-radius: 5px;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 2px rgba(128, 128, 128, 0.1);

    &--highlighted {
      box-shadow: 0 0 2px 2px rgba(128, 128, 128, 0.5);
    }
  }

  &__image {
    flex: 0 1 50%;
    border: 0.5px solid $gray;

    img {
      background: white;
    }
  }
}

.CornerThing {
  $size: 70px;
  $triangleColor: black;

  transition: opacity 0.1s;
  opacity: 0;
  .StashPreview:hover & {
    opacity: 1;
  }

  position: absolute;
  top: 0;
  right: 0;
  width: $size;
  height: $size;
  max-width: 33%;
  max-height: 33%;

  &__triangle {
    width: 100%;
    height: 100%;
    position: absolute;
    border-top: $size solid $triangleColor;
    border-right: $size solid $triangleColor;
    border-bottom: $size solid transparent;
    border-left: $size solid transparent;
  }

  &__ellipsiscontainer {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
