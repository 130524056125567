$link-color: #d60915;
$link-color-dark: #c93232;

$themes: (
  default: (
    bg: white,
    bg-navbar: rgba(255, 255, 255, var(--navbar-scroll-percent)),
    text: #1a1a1a,
    text-card-item-name: #58170d,
    text-inactive: #eee,
    text-link: $link-color,
    text-link-hover: darken($link-color, 15%),
    bg-table-accent: #fafafa,
    bg-table-hover: #e5e5e5,
    bg-card: url('../assets/images/card-background-texture.png'),
    bg-card-header-wash: url('../assets/images/wash-card-header.png'),
    bg-header-wash: url('../assets/images/wash-desktop.png'),
    bg-header-wash-stashes: url('../assets/images/wash-stashes.png'),
    bg-account-papers: url('../assets/images/bg-account-papers.png'),
    bg-subcard: transparentize(white, 0.05),
    bg-card-edge: #fafbfc,
    bg-stash-preview-hover: #f7f7f7,
    bg-image-upload-hover: rgba(255, 255, 255, 0.9),
    shadow-card: none,
    border-data-column: #ced4da,
    border-divider: black,
    dark-inverted-filter: initial,
    dark-only-display: none,
    light-only-display: initial,
    button-muted: #ddd,
    button-muted-hover: #ccc,
    button-muted-active: #bbb,
    border-navbar-toggler: rgba(0, 0, 0, 0.1),
    icon-navbar-toggler: url('../assets/images/icon-hamburger.png'),
    // Bootstrap overrides
      bs-bg-card: #fff,
    bs-btn-outline-bg: transparent,
    bs-btn-primary-bottom-border: darken($link-color, 15%),
  ),
  dark: (
    bg: #2a2a2a,
    bg-navbar: rgba(30, 30, 30, var(--navbar-scroll-percent)),
    text: #fafafa,
    text-card-item-name: lighten(#58170d, 40%),
    text-inactive: #444,
    text-link: $link-color-dark,
    text-link-hover: darken($link-color-dark, 12%),
    bg-table-accent: #2e2e2e,
    bg-table-hover: #3a3a3a,
    bg-card: url('../assets/images/card-background-texture-dark.png'),
    bg-card-header-wash: url('../assets/images/wash-card-header-dark.png'),
    bg-header-wash: url('../assets/images/wash-desktop-dark.png'),
    bg-header-wash-stashes: url('../assets/images/wash-stashes-dark.png'),
    bg-account-papers: url('../assets/images/bg-account-papers-dark.png'),
    bg-subcard: transparentize(#212121, 0.05),
    bg-card-edge: #000,
    bg-stash-preview-hover: #333,
    bg-image-upload-hover: rgba(25, 25, 25, 0.9),
    shadow-card: rgba(255, 255, 255, 10%) 0 0 15px 0px,
    border-data-column: black,
    border-divider: transparentize(white, 0.5),
    dark-inverted-filter: invert(1),
    dark-only-display: initial,
    light-only-display: none,
    button-muted: #555,
    button-muted-hover: #666,
    button-muted-active: #777,
    border-navbar-toggler: rgba(255, 255, 255, 0.25),
    icon-navbar-toggler: url('../assets/images/icon-hamburger-dark.png'),
    // Bootstrap overrides
      bs-bg-card: #111,
    bs-btn-outline-bg: rgba(0, 0, 0, 0.16),
    bs-btn-primary-bottom-border: darken($link-color-dark, 15%),
  ),
);

@mixin for-dark() {
  @media (prefers-color-scheme: dark) {
    [data-theme='auto'] {
      @content;
    }
  }
  [data-theme='dark'] {
    @content;
  }
}

@mixin themed() {
  $theme-map: map-get($themes, 'default') !global;
  @content;
  $theme-map: null !global;

  $theme-map: map-get($themes, 'dark') !global;
  @include for-dark() {
    @content;
  }
  $theme-map: null !global;
}

@function t($key) {
  @return map-get($theme-map, $key);
}

@mixin text-default-theme {
  color: map-get(map-get($themes, 'default'), 'text');
}
