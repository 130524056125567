@import '../styles/theme.scss';

$landscape-mobile-offset-y: -50px;

@mixin landscape-mobile {
  @media (max-height: 600px) {
    @content;
  }
}

@mixin narrow-screen {
  @media (max-width: 450px) {
    @content;
  }
}

@mixin very-narrow-screen {
  @media (max-width: 320px) {
    @content;
  }
}

@include themed() {
  .backdrop {
    $background-offset-y: -100px;
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: t('bg-account-papers');
    background-repeat: no-repeat;
    background-position-y: $background-offset-y;
    background-position-x: right;
    @include landscape-mobile() {
      background-position-y: $background-offset-y + $landscape-mobile-offset-y;
    }

    @include narrow-screen() {
      background-position-x: -200px;
    }
  }
}

.modal {
  position: fixed;
  z-index: 1040;

  @include text-default-theme();

  @include narrow-screen {
    width: 80vw;
  }
  width: 23rem;

  right: 0;
  bottom: 0;
  overflow-y: auto;

  $content-offset-y: 180px;
  top: $content-offset-y;
  @include landscape-mobile() {
    top: $content-offset-y + $landscape-mobile-offset-y;
  }

  &:focus {
    outline: none;
  }
}

@mixin menuItemRightPositioning {
  padding: 0.875rem 2rem;
  padding-left: 0;
  @include very-narrow-screen() {
    padding: 0.875rem 1rem;
    padding-left: 0;
  }
}

.card {
  :global(.card-subtitle) {
    font-style: italic;
    margin-top: 0.3rem;
  }

  .header {
    margin-bottom: 2rem;
    padding-right: 2rem;
    .email {
      font-size: 150%;
      word-break: break-all;
    }
  }

  .items {
    margin-left: auto;
    max-width: 20rem;
    text-align: right;
    margin-bottom: 1rem;

    @include narrow-screen() {
      max-width: 17rem;
    }

    @include very-narrow-screen() {
      max-width: 14rem;
    }

    .menuItem {
      display: flex;
      justify-content: right;
      align-items: center;
      border-bottom: 1px solid black;
      font-size: 0.875rem;
      @include menuItemRightPositioning();

      font-weight: bold;
      text-decoration: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }

      > span {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: black;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
    .tooltipMenuItemsContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0.875rem 2rem;
      padding-left: 0rem;
      gap: 0.875rem;
      @include very-narrow-screen() {
        padding: 0.5rem 1rem;
        gap: 0.5rem;
        padding-left: 0rem;
      }
      .tooltipMenuItem {
        display: flex;
        justify-content: right;
        align-items: center;
        font-size: 0.875rem;
        color: $link-color;
        cursor: pointer;
        > span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: black;
          &:not(:last-child) {
            margin-right: 5px;
            margin-left: 0.5rem;
          }
        }
      }
    }

  }

  .toggleContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include menuItemRightPositioning();
  }

  .toggleLabel {
    margin-bottom: 0.3rem;
    font-weight: bold;
  }
}
