.dropdown-toggle.nav-link {
  &::after {
    content: none;
  }
}

// custom navbar flyout on mobile
@include media-breakpoint-down(lg) {
  .navbar-expand-lg {
    .navbar-toggler:not(.collapsed) {
      visibility: hidden;
    }

    .navbar-collapse {
      position: fixed;
      right: 0;
      top: 0;

      #mobile-navbar-paper {
        position: absolute;
        width: 240px;
        z-index: -1;
        right: 0;
        top: 0;
      }

      .navbar-nav {
        position: absolute;
        top: 1.6rem;
        right: 1.5rem;

        .nav-link {
          padding: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #mobile-navbar-paper {
    display: none;
  }

  // Vertical divider line between items
  @include themed() {
    .navbar-nav > :not(:last-child) {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 15%;
        height: 70%;
        width: 1px;
        background: t('border-divider');
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

@include themed() {
  .navbar.navbar-light .navbar-toggler.collapsed {
    border-color: t('border-navbar-toggler');

    .navbar-toggler-icon {
      height: 10px;
      margin: 10px 0;
      background-image: t('icon-navbar-toggler');
    }
  }
}

@include themed() {
  .navbar.with-backdrop {
    z-index: 1035; // stays below modals
    background-color: t('bg-navbar');
    flex-wrap: wrap;
  }
}
