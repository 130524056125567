@import '../custom';

.link {
  $animDur: 0.2s;
  // "pop"/"snap" animation
  // $animEase: cubic-bezier(0.77, 0, 0.33, 1.4);
  $animEase: ease-in-out;

  @mixin button-position($property, $forCard: false) {
    $contentWidth: 700px;
    @if $forCard {
      $contentWidth: 550px;
    }

    #{$property}: calc(50vw + #{$contentWidth} / 2 + 2rem);
  }

  display: none;
  transition: background-color $animDur $animEase, width $animDur $animEase;
  &.noAnimate {
    &,
    & * {
      transition-duration: 0s !important;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  position: fixed;
  z-index: 1001;
  top: 33vh;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.5);

  &.next {
    @include button-position(left);
    &.forCard {
      @include button-position(left, $forCard: true);
    }
    flex-direction: row;
  }

  &.previous {
    @include button-position(right);
    &.forCard {
      @include button-position(right, $forCard: true);
    }
    flex-direction: row-reverse;
  }

  .itemname {
    display: inline-block;
    line-height: 75px; // same as set in component
    color: black; // TODO: use theme variable?
    white-space: nowrap;
    margin-left: -15px;

    transition: width $animDur $animEase 0s;
    overflow-x: hidden;

    &Invisible {
      display: inline;
      visibility: hidden;
      position: absolute;
      white-space: nowrap;
    }
  }

  &:hover {
    @include ledgerplus-shadow;
    text-decoration: none;
    background: white;

    .itemname {
      transition-delay: calc($animDur / 4);
    }
  }

  .chevron {
    color: map-get($map: $theme-colors, $key: primary);
  }
}
