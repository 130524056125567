@import '../custom';

@include themed() {
  .DateColumnModeButton {
    transition: color 0.2s ease;
    color: t('button-muted');

    &,
    &:active,
    &:focus {
      border: 0;
      background: none;
      outline: none;
    }

    &:hover {
      color: t('button-muted-hover');
    }

    &:active {
      color: t('button-muted-active');
    }
  }
}
