@import '../styles/variables';

$white-tooltip-color: white;
$half-tooltip-arrow-width: calc($tooltip-arrow-width / 2);

.tooltip-white.tooltip {
  .tooltip-inner {
    color: black;
  }

  &.readonly {
    pointer-events: none;
  }

  &.show {
    opacity: 1;
  }
}

.tooltip-white.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height $half-tooltip-arrow-width 0;
      border-top-color: $white-tooltip-color;
    }
  }
}

.tooltip-white.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: $half-tooltip-arrow-width $tooltip-arrow-height
        $half-tooltip-arrow-width 0;
      border-right-color: $white-tooltip-color;
    }
  }
}

.tooltip-white.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 $half-tooltip-arrow-width $tooltip-arrow-height;
      border-bottom-color: $white-tooltip-color;
    }
  }
}

.tooltip-white.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: $half-tooltip-arrow-width 0 $half-tooltip-arrow-width
        $tooltip-arrow-height;
      border-left-color: $white-tooltip-color;
    }
  }
}

.tooltip-white.bs-tooltip-auto {
  &[x-placement^='top'] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^='right'] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^='bottom'] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^='left'] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-white .tooltip-inner {
  @include ledgerplus-shadow;
  max-width: none;
  background-color: $white-tooltip-color;
}
